.user-personal-details {
  width: 100%;
  max-width: 1026px;
  height: 100%;
  padding: 14px 29px;

  &__grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 45px;
  }

  &__subscription {
    width: 100%;
    &-info {
      width: 100%;
      max-width: 1026px;
      height: 142px;
      background-color: #f8f8f8;
      border-radius: 10px;
      padding: 21.5px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__type {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        row-gap: 6px;

        &-text {
          margin-bottom: 0;
          color: #51caad;
          font-size: 24px;
          font-weight: 700;
        }
      }

      &__price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 6px;
      }

      &__duration {
        margin-bottom: 0;
        display: flex;
        column-gap: 8px;

        &-progress {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 8px solid #51caad;
        }
      }
    }
  }
}
