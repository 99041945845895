.coupon-item {
  display: flex;
  align-items: center;
  background: #22222205;
  border-radius: 10px;
  width: fit-content;

  .coupon-text {
    margin: 0;
    color: var(--color-grey-500);
    font-size: 14px;
  }
}
