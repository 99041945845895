.section {
  width: 100%;
  height: auto;
  // max-height: 27.4375rem;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  transition: all 0.5s ease;
  margin-bottom: 1.5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &-text {
        font-size: 1.125rem;
        font-weight: bolder;
      }

      &-sectionTitle {
        color: var(--color-grey-200);
        font-size: 1rem;
        margin-top: -0.7rem;
      }
    }

    &-icons-container {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;

      &__icon {
        cursor: pointer;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        background-color: var(--color-grey-100);
      }
    }
  }

  &__input {
    width: 100%;
    margin-top: -1rem;
    margin-bottom: .5rem;
    max-width: 57.875rem;

    &__label {
      display: block;
      color: var(--color-grey-200);
    }

    &__field {
      width: 100%;
      height: 3.125rem;
      border-radius: 0.5rem;
      padding: 0.5rem;
      border: 2px solid var(--color-grey-100);

      &:focus {
        outline: none;
      }
    }
  }

  // &__editor {
  //   width: 100%;
  //   height: 400px;

  //   &__editor {
  //     width: 100%;
  //     height: 100%;
  //   }

  // }
}
