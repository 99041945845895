.blog-categories {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 1rem 2rem 0 2rem;

  &__header {
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &__right {
      display: flex;
      flex-direction: column;
      &__title {
        font-weight: 800;
        font-size: 20px;
      }
      &__subtitle {
        font-size: 16px;
        color: var(--color-grey-200);
      }
    }
  }
  
  &__body {
    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 20px;

        span {
          color: var(--color-grey-200);
          font-size: 14px;
          font-weight: bold;
        }

        &__category {
          width: 200px;
          height: 44px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
        }

        &__icon {
          cursor: pointer;
          width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 22px;
          color: var(--color-grey-200);
          background-color: var(--color-grey-100);
        }
      }
    }
  }
}
