.admin-layout {
  // .container-fluid > .row{
  //     min-height: 100vh;
  // }

  .layout-pages {
    padding: 1.5rem 2.5rem;
    width: calc(100vw - 300px);

    @media screen and (max-width: $Xlarge) {
      width: 100%;
    }
  }

  // navbar
  .navbar-main {
    background-color: #fff;
    box-shadow: 0px 5px 21px #00000014;
    padding: 0;
    z-index: 99;
    position: sticky;
    top: 0;

    @media only screen and (min-width: $small) {
      min-height: 70px;
    }

    // logo
    .logos {
      display: flex;

      li {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;

        @media only screen and (max-width: $Xlarge) {
          padding-left: 0;
          padding-right: 0;
        }

        &:not(:last-of-type) {
          &::before {
            content: "";
            height: 35px;
            width: 1px;
            background-color: rgba(51, 51, 51, 0.3);
            position: absolute;
            right: 0;
            left: auto;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }

        .navbar-brand {
          margin: 0;
          padding: 0;

          .main-logo {
            max-width: 5.75rem;
            max-height: 5.75rem;
          }
        }
      }
    }

    // menu
    .main-menu {
      .navbar-nav {
        // margin-left: auto;
        // margin-right: auto;
        li {
          &:not(:first-of-type) {
            margin-left: 40px;
          }

          a {
            position: relative;
            font-size: 16px;
            color: var(--color-grey-400);
            font-weight: bold;

            &.active,
            &:hover {
              color: var(--color-primary);

              &::after {
                content: "";
                width: 100%;
                height: 5px;
                background-color: var(--color-primary);
                position: absolute;
                bottom: -15px;
                left: 0;
                right: 0;
                border-radius: 10px 10px 0px 0px;
              }

              span {
                svg {
                  path,
                  circle,
                  line {
                    stroke: var(--color-primary);
                  }
                }
              }
            }
          }
        }
      }
    }

    // setting
    .setting-menu {
      .user-info {
        padding-bottom: 1rem;
      }

      &__item {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1.25rem;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: rgba(201, 199, 199, 0.19);
        }

        &__link {
          color: var(--color-grey-400);
          display: block;
          width: 100%;
          white-space: nowrap;
          text-align: start;
          font-weight: bold;

          i,
          svg {
            margin-inline-end: 1.125rem;
            font-size: 1rem;
            width: 1rem;
            height: 1rem;
            color: #bbbabf;
          }
        }
      }
    }

    .notification {
      @media screen and (max-width: $small) {
        margin-inline-end: 0;
      }

      &.navbar-dropdown {
        .dropdown-menu {
          padding: 1.25rem;
          right: -40px;

          @media screen and (max-width: $Xlarge) {
            right: -65px;
            padding: 0.5rem;
          }

          &::before {
            top: -8px;
            left: auto;
            right: 40px;
          }

          &.show {
            min-width: 25rem;
            margin-top: 1rem;

            @media screen and (max-width: $medium) {
              min-width: 270px;
            }
          }
        }

        &__toggle {
          &::before {
            content: "";
            width: 8px;
            height: 8px;
            border: 2px solid #fff;
            background-color: var(--color-primary);
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top: 2px;
          }
        }
      }

      &__item {
        box-shadow: none;
        background-color: transparent;
        border: none;
        padding: 1.125rem;
        margin-bottom: 0;

        &--new {
          background: rgb(187 186 191 / 8%);
          margin-bottom: 1rem;
        }
      }
    }

    .setting-menu {
      .dropdown-menu {
        &::before {
          top: -8px;
          left: auto;
          right: 40px;
        }
      }

      .navbar-dropdown {
        &__toggle {
          margin-inline-end: 15px;
        }
      }
    }

    .points,
    .lang {
      padding: 5px 18px;
      height: 40px;

      @media screen and (max-width: $medium) {
        padding: 5px 10px;
        height: 30px;
      }
    }

    @media only screen and (max-width: $Xlarge) {
      padding-left: 30px;
      position: fixed;
      width: 100%;
    }
  }

  .revenue-menu {
    color: black;
  }

  // SideBar
  .sidebar-main {
    position: sticky;
    min-height: 66vh;
    max-height: 110vh;
    right: 0;
    top: 0;
    max-width: 270px;
    width: 270px;
    padding-inline: 1.5rem;
    background: var(--color-white) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 21px #00000014;

    @media only screen and (max-width: $Xlarge) {
      display: none;
      top: 50px;
    }

    @media only screen and (max-width: $Xlarge) {
      position: fixed;
      left: -260px;
      z-index: 999;
      transition: 1s;
      height: 100%;
      top: 100px;
      bottom: 0;

      .sidebar-main-toggle {
        z-index: 999;
        display: block;
        font-weight: 900;
        position: fixed;
        left: 10px;
        top: 10px;
        width: 30px;
        font-size: 24px;
        height: 35px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .line {
          width: 20px;
          height: 3px;
          background-color: #000;
          display: block;
          margin: 2px auto;
          border-radius: 20px;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;
        }

        // icon animation
        .hamburger {
          position: relative;
          -webkit-transition: all 0.3s ease-in-out;
          -o-transition: all 0.3s ease-in-out;
          transition: all 0.3s ease-in-out;

          & .line:nth-child(3) {
            width: 15px;
            margin: 2px 0;
          }
        }

        // End icon
      }
    }

    &__content {
      position: sticky;
      top: 80px;
      display: flex;
      flex-direction: column;
      height: 90%;

      ul {
        position: relative;
        border-bottom: 1px solid
          rgba($color: var(--color-grey-400), $alpha: 0.5);
        flex: 1 1 auto;

        // padding: 18px 25px;
        li {
          margin-bottom: 10px;
          transition: all 0.02s ease;

          a {
            display: flex;
            align-items: center;
            border-radius: 14px;
            padding: 15px 20px;

            p {
              margin: 0;
              color: var(--color-grey-500);
              font-size: 15px;
              margin-inline-start: 10px;
            }

            .icon {
              &.icon-plus-filled {
                width: 1.25rem;
                height: 1.25rem;
                margin-block-start: 2px;
              }
            }
          }

          &.active,
          &:hover {
            a {
              svg {
                path,
                circle,
                line {
                  stroke: var(--color-primary);
                }
              }

              .icon {
                color: var(--color-primary);
                fill: var(--color-primary);
                use {
                  stroke: var(--color-primary);
                }

                &.icon-plus-filled {
                  width: 1rem;
                  height: 1rem;
                  margin-inline-start: 2px;
                }
              }

              p {
                color: var(--color-primary);
                font-weight: bold;
              }
            }
          }

          &.active {
            a {
              background: rgba(var(--color-primary-rgb), 0.1);
            }
          }
        }
      }

      @media only screen and (max-width: $Xlarge) {
        z-index: 999;
        position: relative;
      }

      .sign-out-link {
        color: var(--color-grey-500);
        margin: 30px 1.3rem;

        span {
          margin-inline-start: 10px;
        }

        &:hover {
          color: var(--color-primary);

          .icon {
            fill: var(--color-primary);
          }
        }
      }
    }

    .offcanvas {
      width: 260px;
    }

    &.sidebar-mobile {
      display: none;

      @media only screen and (max-width: $Xlarge) {
        display: block;
        top: 50px;
        padding-bottom: 80px;
      }

      .sidebar-mobile__content {
        top: 0;
        overflow: auto;
        height: 100%;

        ul {
          min-height: auto;
          margin: 0 15px;

          li {
            margin-bottom: 0;

            a {
              padding: 15px 0px;
            }
          }

          li.active a,
          li:hover a {
            border-color: transparent;
            background-color: transparent;
          }

          .sidebar-profile-details {
            padding: 20px 15px;
          }
        }

        &__list {
          border-bottom: 1px solid
            rgba($color: var(--color-grey-400), $alpha: 0.5);
          flex: 1 1 auto;
        }
      }
    }
  }

  .course-page {
    @media only screen and (max-width: $Xlarge) {
      flex-direction: column-reverse;
      margin-bottom: 40px;
    }

    .content-coure-page {
      @media only screen and (max-width: $Xlarge) {
        max-width: 100%;
        width: 100%;
      }
    }

    .course-side {
      max-width: 420px;
      width: 425px;
      background: #ffffff;
      box-shadow: 0px 0px 20px #0000001a;
      padding: 0;

      @media only screen and (max-width: $Xlarge) {
        max-width: 100%;
        width: 100%;
      }

      .sidebar-main-toggle {
        display: none;
      }

      .course-side__content {
        position: sticky;
        top: 174px;

        .accordion {
          .accordion-item {
            border: none;
            border-radius: 0;

            .accordion-button {
              &::after {
                content: "\f067";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                background-image: none;
                transition: none;
                color: rgb(136, 136, 136);
              }

              &:not(.collapsed)::after {
                content: "\f068";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                background-image: none;
                transition: none;
                color: rgb(136, 136, 136);
              }
            }

            .accordion-button {
              background-color: #fff;
              border-bottom: 1px solid rgba($color: #ddd, $alpha: 1);
              border-radius: 0;

              &-exam {
                background-color: #0000001a;
              }

              &:focus {
                outline: none;
                box-shadow: none;
              }

              &.disabled {
                &::after {
                  display: none;
                }
              }
            }
          }

          .accordion-body {
            background-color: rgba($color: #000000, $alpha: 0.04);
            padding: 10px;

            ul {
              li {
                margin-bottom: 10px;

                a {
                  padding: 12px 15px;
                  display: flex;
                  align-items: center;
                  border-radius: 10px;
                  color: var(--color-grey-500);
                }

                &.active,
                &:hover {
                  a {
                    background-color: rgba(var(--color-grey-500-rgb), 0.1);
                  }
                }

                &.done {
                  .icon {
                    color: var(--color-primary);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .page-header {
    background: #ffffff;
    box-shadow: 0px 3px 26px #0000001a;
    position: sticky;
    top: 70px;
    display: flex;
    align-items: center;
    z-index: 6;
    padding: 22px 20px;

    @media only screen and (max-width: $Xlarge) {
      position: static;
      display: flex;
      flex-direction: column;
    }

    &__title {
      .breadcrumb {
        margin-bottom: 6px;

        &-item {
          color: var(--color-grey-400);

          &.active {
            opacity: 0.5;
            color: #bbbabf;
          }
        }
      }
    }

    &__progress {
      margin-left: auto;
      width: 50%;
      display: flex;

      @media only screen and (max-width: $Xlarge) {
        margin-left: 0;
        width: 100%;

        .ms-auto {
          margin-left: 0 !important;
          margin-right: 0 !important;
        }

        .progress-container {
          flex-direction: column;

          p {
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  .head-text-title {
    p {
      margin: 0;
    }
  }
}
