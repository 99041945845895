.blog-page {
  position: relative;
  background-color: #fafafa;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  .filled-btn {
    background-color: var(--color-primary);
  }

  .outlined-btn {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
  }

  label {
    display: block;
    margin-bottom: 1rem;
    font-size: 16px;
    color: var(--color-grey-200);
  }

  &__sticky-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 70px;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0px 3px 3px #00000014;
    padding: 20px 40px;

    &__title {
      font-size: 24px;
      font-weight: bold;
    }

    &__buttons {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
  }

  &__content {
    padding: 1rem 0;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1.5rem;
    margin: 0 auto;

    &__header {
      background-color: #fff;
      width: 100%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 20px;
      border-radius: 1rem;
      box-shadow: 0px 3px 3px #00000014;

      &-inputs {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;

        &__title {
          width: 100%;
        }

        &__author-date {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 20px;
        }

        &__categories-tags {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 20px;
        }
      }

      &__description {
        width: 100%;
        color: var(--color-grey-400);
        margin-top: -0.5rem;
      }

      .blog-page__content__image-upload {
        display: flex;
        flex-direction: column;

        .blog-page__content__image-upload__title {
          font-size: 24px;
          font-weight: bold;
          color: var(--color-grey-800);
        }

        .blog-page__content__image-upload__description {
          font-size: 16px;
          color: var(--color-grey-200);
          margin-top: -0.5rem;
        }
      }
    }

    &__data {
      width: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 1rem 2rem;
      background-color: #fff;
      border-radius: 1rem;
      row-gap: 1rem;
      box-shadow: 0px 3px 3px #00000014;

      &__title {
        font-size: 1.5rem;
        font-weight: bolder;
      }

      &__sections {
        width: 100%;
      }
    }

    &__add-section {
      width: 100%;
      max-width: 61.625rem;
      margin: 0 0 1rem 2rem;
      height: 6.25rem;
      display: flex;
      justify-content: flex-start;
      column-gap: 0.2rem;
      align-items: center;
      border-radius: 0.75rem;
      color: var(--color-grey-200);
      border: 3px dashed var(--color-grey-100);
      padding-right: 0.5rem;
      font-size: 1.125rem;
    }

    &__meta {
      background-color: #fff;
      width: 100%;
      padding: 0.6rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 20px;
      border-radius: 1rem;
      box-shadow: 0px 3px 3px #00000014;

      &__label {
        display: block;
        color: var(--color-grey-200);
      }

      &__field {
        width: 100%;
        height: 3.125rem;
        border-radius: 0.5rem;
        padding: 0.5rem;
        border: 2px solid var(--color-grey-100);

        &:focus {
          outline: none;
        }
      }
    }
  }
}
