

@mixin spacing {
  @each $key, $value in $spacing-values {
    $valueRem: calc($value / 16px) * 1rem;
    $suffix: if($key=='12', '', "-#{$key}");

    .p#{$suffix} {
      padding:  $valueRem;
    }

    .px#{$suffix} {
      padding-inline:  $valueRem;
    }

    .py#{$suffix} {
      padding-block:  $valueRem;
    }

    .pt#{$suffix} {
      padding-top:  $valueRem;
    }

    .pb#{$suffix} {
      padding-bottom:  $valueRem;
    }

    .ps#{$suffix} {
      padding-inline-start:  $valueRem;
    }

    .pe#{$suffix} {
      padding-inline-end:  $valueRem;
    }

    .m#{$suffix} {
      margin:  $valueRem;
    }

    .mx#{$suffix} {
      margin-inline:  $valueRem;
    }

    .my#{$suffix} {
      margin-block:  $valueRem;
    }

    .mt#{$suffix} {
      margin-top:  $valueRem;
    }

    .mb#{$suffix} {
      margin-bottom:  $valueRem;
    }

    .ms#{$suffix} {
      margin-inline-start:  $valueRem;
    }

    .me#{$suffix} {
      margin-inline-end:  $valueRem;
    }
  }
}

@include spacing;