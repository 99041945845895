.sticky-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 70px;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0px 3px 3px #00000014;
    padding: 20px 40px;

    &__title {
      font-size: 24px;
      font-weight: bold;
    }

    &__buttons {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
  }