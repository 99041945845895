.change-password-modal {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  width: 500px;
  height: 380px;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #00000029;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff;
  z-index: 9999;

  &__title {
    color: #000;
    font-weight: bold;
    font-size: 14px;
    margin: 8px;
  }

  &__text {
    color: var(--color-grey-200);
    font-size: 12px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    row-gap: 1rem;
    margin-top: 16px;

    &__input {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    column-gap: 16px;
  }

  &__cancel {
    color: #000;
  }
}
