.notification-bar {
    width: 100%;
    padding: 1rem .5rem 1rem 2.5rem;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid transparent;
    background-color: #FFF;
    margin-top: 6px;
    box-shadow:0px 0px 15px rgba(0, 0, 0, 0.0784313725);
    z-index:90;
    position: relative;
}
.notification-mssage{
    padding:1rem 1rem 1rem 0.5rem;
    text-align: right;
    display: inline-flex;
    border-radius: 5px;
    width: 100%;
    .icon {
        fill: #cf8600
    }
    .text{
        padding-right: 10px;
        .inner{
            font-size: 14px;
        }
        .head{
            margin-bottom: 5px;
            font-size: 16;
        }
    }
}
  
.success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.warning {
    background-color: #fff5c3;
    color: #000;
    border-color: #ffeeba;
}

.error {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .notification-bar {
        font-size: 14px;
        padding: 8px 15px;
    }
}
