.search-results {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  &__hero {
    position: relative;
    height: 100px;
    width: 100%;
    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      opacity: 0.8;
    }

    &-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
    }
  }
  &__content {
    margin-top: 50px;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
