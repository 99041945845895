.blogs-page {
  position: relative;
  padding: 1.5rem 3rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 2.7rem;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .blogs-page__header__title-subtitle {
      display: flex;
      flex-direction: column;

      .blogs-page__header__title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: -2px;
        color: var(--color-grey-800);
      }

      .blogs-page__header__subtitle {
        display: block;
        font-size: 16px;
        color: var(--color-grey-200);
      }
    }

    &__left-side {
      display: flex;
      align-items: center;
      column-gap: 20px;

      &__settings {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e7e7e7;
        border-radius: 12px;
      }
    }
  }

  &__my-blogs-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 0.4rem;
    h6 {
      color: var(--color-grey-200);
      border-bottom: solid 1px var(--color-grey-100);
      padding-bottom: 0.5rem;
    }
  }
}
