.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;

  p {
    color: var(--color-grey-200);
    font-weight: bold;
    font-size: 14px;
  }
  .container {
    position: relative;
    flex: 1;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border: 2px solid var(--color-grey-100);

    .date-picker {
      width: 455px;
      height: 46px;
      border: 0;
      font: inherit;
      background: transparent;
      color: var(--color-grey-400);
      font-size: 16px;
      font-weight: normal;

      &:focus {
        outline: 0;
      }

      &::placeholder {
        color: var(--color-grey-200);
        font-size: 14px;
        font-weight: normal;
      }
    }
    .icon {
      position: absolute;
      top: 15px;
      left: 10px;
    }
  }
}
