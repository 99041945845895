.image-upload {
  position: relative;
  display: flex;
  border: 2px dashed #ccc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
  max-width: 500px;

  &.has-image {
    border: none;
    padding: 0;
  }

  .upload-label {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: 30px;
  }

  input {
    display: none;
  }

  .image-preview {
    position: relative;
    width: 500px;
    height: 300px;
    overflow: hidden;
    margin-top: 10px;
    border-radius: 1rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .image-actions {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 1rem;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      background: rgba(0, 0, 0, 0.2);

      button {
        background: none;
        color: #fff;
        border: 1px solid #fff;
        padding: 8px 16px;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 10px;
}
