.users-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.search-form {
  width: 300px;
  height: 50px;
  display: flex;
  padding-right: 10px;
  align-items: center;
  border-radius: 10px;
  column-gap: 10px;
  border: 1px solid #e2e2e2;
  overflow: hidden;

  &__input {
    height: 100%;
    width: 90%;
    border: none;
    outline: none;
    padding: 0 10px;
    font-size: 16px;
    font-weight: bold;

    &:focus,
    &:active {
      border: none;
      outline: none;
    }
  }

  &__icon {
    cursor: pointer;
  }
}
