.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;

  p {
    color: var(--color-grey-200);
    font-weight: bold;
    font-size: 14px;
  }

  .select-with-checkbox {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    height: 50px;
    width: 100%;
    flex: 1;
    border: 2px solid var(--color-grey-100);
    border-radius: 12px;

    &__value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;

      p {
        color: var(--color-grey-200);
        font-weight: normal;
        font-size: 14px;
      }
    }

    &__options {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: 300px;
      overflow-y: scroll;
      border: 1px solid var(--color-grey-100);
      border-radius: 0.5rem;
      padding: 0.5rem;
      background-color: var(--color-white);
      z-index: 1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &::-webkit-scrollbar {
        width: 5px;
        background-color: var(--color-gray-200);
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);
        border-radius: 10px;
      }

      &::-webkit-scrollbar-corner {
        background-color: #f0f0f0;
      }

      span {
        color: #0c0c0c;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    input[type="checkbox"] {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #999;
      border-radius: 4px;
      position: relative;

      &:checked {
        background-color: var(--color-primary);
        border-color: #eee;

        &::after {
          content: "\2713";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 16px;
          font-weight: 800;
        }
      }
    }

    &__selected-items {
      display: flex;
      column-gap: 5px;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      &__item {
        display: flex;
        align-items: center;
        width: auto;
        height: 28px;
        column-gap: 5px;
        background-color: #f2f2f4;
        font-size: 12px;
        font-weight: bold;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
  .error {
    color: red;
    font-size: 12px;
    font-weight: 400;
  }
}
