.header {
  width: 100%;
  max-width: 1200px;
  padding: 1rem 2rem;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  border-radius: 1rem;

  &-upper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;

    &__title {
      display: flex;
      align-items: center;
      column-gap: 8px;

      &__text {
        font-size: 1.125rem;
        font-weight: bolder;
        margin: 0;
      }

      &__dot {
        height: 8px;
        width: 8px;
        background-color: #ccc;
        border-radius: 50%;
      }

      button {
        text-decoration: underline;
        color: #af151f;
        font-size: 16px;
        font-weight: 800;
      }
    }

    &__status {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 0.625rem;

      &__text {
        color: var(--color-grey-200);
        font-size: 14px;
      }

      &__parent {
        background-color: rgba(129, 58, 138, 0.1);
        border-radius: 0.5rem;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.3rem;

        &__dot {
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #813a8a;
        }

        &__value {
          color: #813a8a;
          font-size: 0.75rem;
        }
      }
    }
  }
}
