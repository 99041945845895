.my-blogs-section__card {
  position: static;
  width: 800.5px;
  max-width: 800.5px;
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--color-grey-100);
  overflow: hidden;

  &:last-child {
    border-bottom: none;
  }

  &__blog-details {
    // flex: 1.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: space-between;
    overflow: hidden;

    &__authorName-authorImg {
      display: flex;
      align-items: center;
      column-gap: 0.5rem;
      justify-content: flex-start;

      &__authorName {
        color: var(--color-grey-800);
        font-weight: bold;
        font-size: 14px;
      }
    }

    &__title {
      color: var(--color-grey-800);
      font-weight: bold;
      font-size: 1.25rem;
      width: 398px;
      height: 50px;
      max-height: 50px;
      max-width: 398px;
      margin: 0;
    }

    &__subtitle {
      color: var(--color-grey-200);
      width: 460px;
      height: 48px;
      max-width: 460px;
      text-overflow: ellipsis;
      max-lines: 3;
      overflow: hidden;
    }

    &__category {
      width: auto;
      padding: 0.25rem 0.5rem;
      max-width: 12.5rem;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #ee5e44;
      border-radius: 1.2rem;
      font-size: 0.75rem;
    }

    &__date {
      display: block;
      color: var(--color-grey-200);
      font-size: 0.75rem;
      max-height: 14px;
    }
  }

  &__image {
    position: relative;
    flex: 1;
    width: 320px;
    height: 200px;

    &__image-holder {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      overflow: hidden;
      background-color: #f2f2f2;
    }

    &__actions {
      position: absolute;
      top: 10px;
      left: 0px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;

      &__featured {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 12px;
      }

      &__icon-status {
        display: flex;
        column-gap: 0.4rem;
        justify-content: flex-end;
        align-items: center;

        &__status {
          color: rgba(129, 58, 138, 0.9);
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: 800;
          width: 111px;
          height: 30px;
          border-radius: 20px;
        }

        button {
          background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 12px;
          box-shadow: 0px 4px 20px #0000001a;
        }
      }

      &__modal {
        position: absolute;
        top: 55px;
        left: 5px;
        width: 210px;
        height: 168.95px;
        padding: 0.5rem;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 20px #00000029;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        background-color: #000;

        &::before {
          content: "";
          position: absolute;
          top: -9.5px; /* Adjust this value to position the triangle */
          left: 10px;
          border-style: solid;
          border-width: 0 10px 10px 10px; /* Adjust the size of the triangle */
          border-color: transparent transparent #fff transparent; /* Change the color here */
        }

        &__list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          // row-gap: 0.6rem;
          // height: 80%;
          border-bottom: 1px solid var(--color-grey-100);

          li {
            display: block;
            cursor: pointer;
            font-size: 14px;
            font-weight: bold;
            padding: 12px;
          }
        }

        &__displayCard {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            height: 20px;
            width: auto;
            margin-top: 10px;
          }
        }

        .switch {
          position: relative;
          display: block;
          width: 40px;
          height: 20px;
        }

        .switch input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        /* The slider */
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          transition: 0.4s;
          border-radius: 20px;
        }

        .slider.round {
          border-radius: 20px;
        }

        .switch input:checked + .slider {
          background-color: green;
        }

        .slider:before {
          position: absolute;
          content: "";
          height: 16px; /* Adjusted height */
          width: 16px; /* Adjusted width */
          right: 2px; /* Adjusted position */
          bottom: 2px; /* Adjusted position */
          background-color: white;
          transition: 0.4s;
          border-radius: 50%;
        }

        .switch input:checked + .slider:before {
          transform: translateX(-20px);
        }
      }

      &__modal-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 8px;

        &__image {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #f7e7e8;
        }

        &__title {
          font-size: 22px;
          font-weight: 800;
        }

        &__text {
          font-size: 16px;
          color: var(--color-grey-200);
          max-width: 358px;
          text-align: center;
        }

        &__buttons {
          width: 100%;
          align-self: flex-end;
          flex-direction: row-reverse;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          &__cancel {
            border: 1px solid var(--color-primary);
            color: var(--color-primary);
          }

          &__delete {
            background-color: var(--color-primary);
          }
        }
      }
    }
  }
}
