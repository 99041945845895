.navbar-dropdown {
  --dropdown-min-width: 18.75rem;
  text-align: end;
  position: relative;

  .dropdown-menu {
    background-color: #fff;
    box-shadow: 0px -4px 20px #00000014;
    border-radius: 1rem;
    text-align: start;
    border: none;
    right: 0;
    left: auto;
    top: 120%;

    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #fff;
      position: absolute;
      left: 13px;
      top: -7px;

      @media only screen and (max-width: $Xlarge) {
        display: none;
      }
    }

    &.show {
      min-width: var(--dropdown-min-width);
    }

    &-title {
      padding: 1.125rem;
    }
  }
}