// Main
body {
  font-family: $Almarai;
  padding: 0 !important;
  color: var(--color-black);

  #maincontent {
    direction: ltr;
  }

  .p-4 {
    @media only screen and (max-width: $medium) {
      padding: 10px !important;
    }
  }
}

hr {
  color: var(--color-black);
  opacity: 0.10;
  margin-block: 2rem;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

.flex-0 {
  flex: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  border: none;
  background: transparent;

  &:focus,
  &:hover {
    outline: none;
  }
}

.min-w {
  &-140 {
    width: 140px !important;
  }
}

.state {
  &-green {
    color: rgba(1, 194, 153, 1);
  }

  &-red {
    color: rgba(175, 21, 31, 1);
  }

  &-yellow {
    color: rgba(241, 174, 78, 1);
  }
}

.align-center {
  align-items: center;
}

.btn {
  border-radius: 12px;
  box-shadow: 0px 3px 20px #0000001a;
  font-size: 17px;
  padding: 10px 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: #fff;
    opacity: 0.8 !important;
  }

  &.font-15 {
    font-size: 15px;
  }

  &-main-color {
    background: var(--color-primary);
    border-color: var(--color-primary);
    box-shadow: 0px 4px 20px #AF151F4D;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: var(--color-primary) !important;
      border-color: var(--color-primary) !important;
    }
  }

  &-whats-app-color {
    background: $color-green-400;
    border-color: $color-green-400;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: $color-green-400 !important;
      border-color: $color-green-400 !important;
    }
  }

  &-main-color-border {
    background: transparent;
    color: var(--color-primary);
    border-color: var(--color-primary);

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: transparent !important;
      color: var(--color-primary) !important;
      border-color: var(--color-primary) !important;
    }
  }

  &-grey-color {
    background: rgba(#000000, 0.05);
    box-shadow: none;
    color: var(--color-grey-800);
    border-color: transparent;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba($color: #000000, $alpha: 0.05) !important;
      color: var(--color-grey-800) !important;
      border-color: transparent !important;
    }
  }

  &-grey-color-border {
    background: transparent;
    color: #000;
    border-color: rgba(var(--color-grey-800-rgb), 0.2);
    box-shadow: none;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: transparent !important;
      color: var(--color-grey-800) !important;
      border-color: var(--color-grey-800) !important;
    }
  }

  &-dark-color {
    background: var(--color-grey-400);
    box-shadow: none;
    border-color: transparent;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: var(--color-grey-400);
      color: #fff !important;
      border-color: transparent !important;
      opacity: 0.8 !important;
    }
  }

  &-red-light-color {
    background: rgba(var(--color-red-rgb), 0.05);
    box-shadow: none;
    color: var(--color-red);
    border-color: transparent;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba(var(--color-red-rgb), 0.05) !important;
      color: var(--color-red) !important;
      border-color: transparent !important;
    }
  }

  &-grey-500-color {
    background: rgba(var(--color-grey-500-rgb), 0.1);
    box-shadow: none;
    color: var(--color-grey-800);
    border-color: transparent;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba($color: #000000, $alpha: 0.05) !important;
      color: var(--color-grey-800) !important;
      border-color: transparent !important;
    }
  }

  &.btn-icon-border {
    line-height: 33px;

    &:hover,
    &:active,
    &:focus,
    &.active {
      background: rgba($color: #000000, $alpha: 0.05) !important;
      color: var(--color-grey-800) !important;
      border-color: transparent !important;
    }
  }

  &-size {
    &-lg {
      padding-block: 0.75rem;
      padding-inline: 8rem;

      @media screen and (max-width: $small){
        padding: 0.75rem 4rem;

      }
    }
  }
}

.text-link {
  &-red {
    color: var(--color-red);
    font-size: 17px;
    font-weight: bold;

    &:hover {
      color: var(--color-red);
      opacity: 0.8;
    }

    &.under-line {
      text-decoration: underline;
      display: inline-block;
      padding: 0 3px;

      &:hover {
        text-decoration: none;
      }
    }
  }


  &-grey {
    color: var(--color-grey-400);
    font-size: 17px;
    font-weight: bold;

    &:hover {
      color: var(--color-grey-400);
      opacity: 0.8;
    }
  }
}

.label {
  border-radius: 12px;
  padding: 4px 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;

  &-black {
    background-color: rgba($color: #000000, $alpha: 0.1);
    color: #222;
  }

  .avatar-group-total {
    border: 1px solid #dee2e6;
    background-color: #e6e6e6;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    color: var(--color-grey-400);
  }

  &-grey {
    background-color: rgba(#000000, 0.1);
    color: var(--color-grey-500);
  }

  &-red {
    background-color: rgba(var(--color-red-rgb), 0.1);
    color: var(--color-red);
  }

  &-green {
    background-color: rgba(var(--color-success-rgb), 0.1);
    color: var(--color-success);
  }

  &-yellow {
    background-color: rgba(#fef0cc, 0.5);
    color: #000;
  }
}

.course-label {
  border-radius: 12px;
  padding: 4px 12px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #fff;

  &-red {
    background-color: var(--color-red-200);
  }
}

.avatar-group {
  display: flex;
  flex-wrap: wrap;

  li {
    margin-left: calc(-4px);
    margin-right: calc(-4px);
    width: 30px;
    height: 30px;
    position: relative;

    img {
      border: 1px solid #dee2e6;
    }

    .avatar-group-total {
      border: 1px solid #dee2e6;
      background-color: #e6e6e6;
      width: 30px;
      height: 30px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: bold;
      color: var(--color-grey-400);
    }
  }
}

.delete-img-icon {
  box-shadow: 0px 4px 30px #00000014;
  border-radius: 8px;
  background-color: #ffffff;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.badge {
  padding: 5px 11px;
  font-size: 14px;
  border-radius: 10px;

  &__success {
    background-color: var(--color-success);
    color: #fff;

    &--light {
      background-color: rgba(var(--color-success-rgb), 0.08);
      color: var(--color-success);
    }
  }

  &__danger {
    background-color: var(--color-primary);
    color: #fff;

    &--light {
      background-color: rgba(var(--color-primary-rgb), 0.08);
      color: var(--color-primary);
    }
  }
}

.btn-icon-border {
  width: 50px;
  height: 50px;
  border: 2px solid #70707033;
  font-size: 18px;
  text-align: center;
  border-radius: 12px;
  line-height: 45px;
}

.border {
  &-color {
    &-grey-light {
      border-color: #e2e2e2;
    }
  }
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}