.blog-settings {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  &__header {
    width: 100%;
    height: 120px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 2rem 0 2rem;
    box-shadow: 0px 5px 5px #00000014;
    border-radius: 0;
    margin: 0;

    h2 {
      margin: 0;
      font-size: 24px;
      font-weight: 800;
    }

    .tabs {
      cursor: pointer;
      display: flex;
      column-gap: 30px;

      .tab {
        padding-bottom: 8px;
        font-size: 14px;
        color: var(--color-grey-200);
      }

      .active {
        color: #af151f;
        border-bottom: 2px solid #af151f;
        font-weight: 800;
      }
    }
  }
}
