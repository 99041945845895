.preview-blog-wrapper {
  padding: 2rem;
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // row-gap: 1.5rem;
  margin: 0 auto;

  .preview-blog {
    // width: 800px;
    // padding: 20px;
    // margin: 0 auto;
    display: flex;
    column-gap: 70px;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      row-gap: 12px;
      &-hero-img {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          width: 800px;
          height: 400px;
          border-radius: 16px;
        }
      }

      &-title {
        font-size: 36px;
        color: #222222;
        max-width: 621px;
      }

      &-date {
        display: flex;
        align-items: center;
        column-gap: 4px;

        &__date {
          font-size: 12px;
          color: rgba(136, 136, 136, 1);
        }

        &__dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: rgba(136, 136, 136, 1);
        }

        &__read {
          font-size: 12px;
          color: rgba(136, 136, 136, 1);
        }
      }

      &__author {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 12px;

        span {
          font-size: 12px;
          color: rgba(136, 136, 136, 1);
        }

        &-name {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
        }
      }
      &__description {
        font-size: 18px;
        font-weight: normal;
        color: rgba(136, 136, 136, 1);
      }
    }
  }
}
