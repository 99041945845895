@mixin font-color() {
  @each $key , $color in $theme-colors {
    .font-color-#{$key} {
      color: $color;
    }
  }
}

@mixin bg-color {
  @each $colorName , $color in $theme-colors {
    .bg-color-#{$colorName} {
      background-color: $color;
    }

    @each $number , $value in $opacity {
    .bg-color-#{$colorName}-opacity-#{$number} {
      background-color: rgba($color, $value)
    }
  }
}
}

@include font-color();
@include bg-color;