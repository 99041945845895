// media Query Sizes
$small: 768px;
$medium: 991px;
$large: 1024px;
$Xlarge: 1300px;
// fonts
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@400;700;800&display=swap");

$Almarai: "Almarai", sans-serif;

// Colors
$color-primary:  #af151f;
$color-pink: rgba(175, 21, 31, 0.08);
$color-white: #fff;
//grey
$color-grey-50:  #e6e6e8;
$color-grey-60:  #f8f8f8;
$color-grey-100: #EFEFF0;
$color-grey-200: #BBBABF;
$color-grey-300: #707070;
$color-grey-400: #888;
$color-grey-500: #777;
$color-grey-600: #9E9DA4;
$color-grey-700: #333;
$color-grey-800: #222;
$color-grey-900: #1B1829;
//blue
$color-blue-700: #011B32;
$color-blue-800: #001425;
//green
$color-success: #36ba8e;
$color-green-50: #E4F9F2;
$color-green-200: #21D59B;
$color-green-300: #01C299;
$color-green-400: #25D366;
$color-green-600: #198754;
//yellow
$color-yellow-400: #f7b500;
//red
$color-red: #af151f;
$color-red-200: #c15258;
//Orange
$color-orange-500: #F17300;
//Purple
$color-purple-500: #9D4EDD;
//RGB
$color-success-rgb: 54, 186, 142;
$color-primary-rgb: 175,21 ,31;
$color-red-rgb: 175,21 ,31;
$color-grey-300-rgb: 112,112,112;
$color-grey-700-rgb: 51,51,51;
$color-grey-500-rgb: 119, 119, 119;
$color-grey-800-rgb: 34, 34, 34;
$color-grey-400-rgb: 136, 136, 136;
$color-grey-900-rgb: 27, 24, 41;
$color-white: #fff;
$color-black: #000;

$theme-colors: (
  primary: $color-primary,
  red : $color-primary,
  white: $color-white,
  black: $color-black,
  grey-light: $color-grey-200,
  grey-50: $color-grey-50,
  grey-60: $color-grey-60,
  grey-100: $color-grey-100,
  grey-200: $color-grey-200,
  grey-300: $color-grey-300,
  grey: $color-grey-400,
  grey-500: $color-grey-500,
  grey-600: $color-grey-600,
  grey-700: $color-grey-700,
  grey-900: $color-grey-900,
  dark: $color-grey-800,
  green-50: $color-green-50,
  green-200: $color-green-200,
  green: $color-green-600,
  green-300: $color-green-300,
  gold: $color-yellow-400,
  blue-700: $color-blue-700,
  blue-800: $color-blue-800,
);

:root {
  @each $color, $value in $theme-colors {
    --color-#{$color}: #{$value};
    --color-#{$color}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
  }
}

// **** Fonts **** //
$base-font-size: 16px;
$font-sizes: (
  10: 10px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  17: 17px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  26: 26px,
  28: 28px,
  30:30px,
  31: 31px,
  32:32px,
  39: 39px,
  50:50px
  );

  $font-weight: (
    bold: bold,
    extrabold: 800,
    regular : normal
  );

  $line-height-values : (
    17: 17px,
    20: 20px,
    22: 22px,
    24: 24px,
    30: 30px,
    36: 36px,
    40: 40px,
    56:56px
  );


// **** Spacing **** //
$spacing-values: (
  6: 6px,
  10: 10px,
  9: 9px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  18: 18px,
  19: 19px,
  30: 30px,
  33:33px,
  36: 36px,
  38: 38px,
  34: 34px,
  25: 25px,
  22: 22px,
  21: 21px,
  26: 26px,
  28: 28px,
  20: 20px,
  23: 23px,
  40: 40px,
  44: 44px,
  48: 48px,
  50: 50px,
  55: 55px,
  62:62px,
  70:70px,
  82:82px,
  93: 93px,
  100:100px,
  116:116px,
  121:121px,
  );

// **** box-shadow **** //
$box-shadows: (
  2: 0 0 20px #00000014,
  3:  0px 0px 30px #00000033,
  );

  // **** radius **** //

  $radius: (
    10: 10px,
    12: 12px,
    16: 16px,
    20: 20px,
  );

  $opacity : (
    4: 0.04,
    10: 0.10,
    50: 0.50,
    100: 1
  );

  $line-clamps: (
    1: 1,
    2: 2,
    3: 3
  )