@use "../helpers/variables"as v;

.home-page {

  &__start-learning {
    @media screen and (max-width: v.$Xlarge) {
      margin-top: 8rem;
    }

    @media screen and (max-width: v.$small) {
      margin-top: 6rem;
    }

    img {
      @media screen and (max-width: v.$medium) {
        width: 100%;
        object-fit: cover;
      }

      @media screen and (max-width: v.$small) {
        margin-top: 1rem;
      }
    }
  }

  &__successful-investment {
    background-color: rgba(var(--color-grey-900-rgb), 0.04);
    margin-top: 4.313rem;
    padding-top: 5.625rem;
    padding-bottom: 5.25rem;

    &__title {
      margin-bottom: 3.063rem;
    }

    &__name {
      padding-inline: 100px;
      margin-top: 30px;

      @media screen and (max-width: 1199px) {
        padding-inline: 50px;
      }

      @media screen and (max-width: v.$small) {
        padding-inline: 0;
        ;
      }
    }
  }

  &__our-numbers {
    margin-top: 8.25rem;
    background-image: url("../../../public/images/home/ar-map.svg");
    background-repeat: no-repeat;
    background-position: center;
    height: 60vh;
    background-size: contain;
  }

  &__traineers-insights {

    &__title {
      padding-inline: 100px;
      width: 72%;
      margin: 0 auto;
      line-height: 1.5;

      @media screen and (max-width: 1199px) {
        width: 100%;

      }

      @media screen and (max-width: v.$small) {
        padding-inline: 15px;
      }
    }

    &__video-player {
      >div {
        @media screen and (max-width: v.$small) {
          width: 100% !important;
        }
      }

      iframe {
        border-radius: 20px;
      }
    }
  }

  &__need-support {
    margin-top: 77px;
  }

  &__join-tadrab {
    &__swiper {
      position: relative;
      margin-bottom: 39px;

      .swiper-slide {
        position: relative;
        img {
          object-fit: cover;
        }
        &::after {
          content: "";
          position: absolute;
          background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 42%, rgba(0, 0, 0, 0.9019607843) 100%) 0% 0% no-repeat padding-box;
          border-radius: 0px 0px 12px 12px;
          left: 0;
          right: 0;
          height: 170px;
          bottom: 0;
        }

        &__info {
          position: absolute;
          bottom: 0;
          z-index: 3;
        }
      }

      &__action {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        width: 50px;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #00000033;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &.swiper-button-disabled {
          color: #999999;
        }

        &.prev {
          right: -2.3rem;
        }

        &.next {
          left: -2.3rem;
        }
      }
    }
  }

  &__how-start {
    padding-block: 92px;

    &__course {
      padding-block: 72px;

      &__img {
        width: 354px;
        height: 349px;

        @media screen and (max-width: 1199px) {
          width: 254px;
          height: 249px;
        }

        @media screen and (max-width: v.$small) {
          width: 154px;
          height: 149px;
        }
      }

      &__info {
        padding-inline: 82px;

        @media screen and (max-width: v.$small) {
          padding-inline: 15px;
        }

        p {
          width: 65%;

          @media screen and (max-width: 1199px) {
            width: 100%;
          }
        }

        &--second {
          padding-inline: 170px;

          @media screen and (max-width: v.$small) {
            padding-inline: 15px;
          }
        }

      }
    }
  }
}