.modal-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding: 8px;
  align-items: flex-start;
  margin: 0

  &__title {
    margin: 0;
    font-size: 20px;
    font-weight: 800;
  }

  &__subtitle {
    margin: 0;
    font-size: 16px;
    color: var(--color-grey-200);
  }
}

.modal-form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  &__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    flex: 2;

    &__color {
      width: 100%;
      border: 2px solid var(--color-grey-100);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;

      p {
        margin: 0;
        font-size: 16px;
        font-weight: 800;
        color: var(--color-grey-400);
      }

      input[type="color"] {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 8px;
        border: 0;
        outline: 0;
        padding: 0;
        &:focus {
          outline: 0;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    column-gap: 20px;
  }
}
