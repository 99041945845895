@mixin font-size {
  @each $size, $value in $font-sizes {
    .font_#{$size} {
      font-size: calc($value / $base-font-size) * 1rem;
    }
  }
}

@mixin font-weight {
  @each $key, $value in $font-weight {
    .font-#{$key} {
      font-weight: $value;
    }
  }
}

@mixin line-height {
  @each $key, $value in $line-height-values {
    .lh-#{$key} {
     line-height: $value;
    }
  }
}

@mixin line-clamp {
  @each $key, $value in $line-clamps {
    .line-clamp-#{$key} {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: $value;
    }
  }
}

@include font-size;
@include font-weight;
@include line-height;
@include line-clamp;