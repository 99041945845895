.main-modal {

  // Padding
  .modal-header,
  .modal-body,
  .modal-footer {
    border: none;
    padding-left: 30px;
    padding-right: 30px;
  }

  .modal-dialog {
    .modal-content {
      box-shadow: 0px 0px 30px #0000001A;
      border-radius: 20px;
    }
  }

  // head
  .modal-header {
    .btn-close {
      background: rgba(var(--color-grey-800-rgb),0.04);
      border-radius: 12px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .modal-title {
      color: var(--color-grey-800);
      font-size: 22px;
      font-weight: bold;
    }
  }

  // body
  .modal-body {}

  // footer
  .modal-footer {}

}

.create-post-button-modal {
  &__uploaded {
    margin-bottom: 40px;
    text-align: center;

    .image-result {
      position: relative;
      display: inline-block;

      .delete-img-icon {
        position: absolute;
        left: 15px;
        top: 15px;
      }

      img {
        border-radius: 16px;
        max-width: 100%;
      }
    }

  }
}

.answers-modal {
  &__select-course {
    margin-top: 12px;
  }
  &__footer {
    background-color: rgba(0,0,0,0.04);
    margin-inline: -30px;
    margin-bottom: -1rem;
    padding: 25px 30px;
  }
}