.tag-wrapper {
  width: auto;
  max-width: 200px;
  height: 40px;
  max-height: 137px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 8px;
  font-size: 12px;
  color: var(--grey);
}
