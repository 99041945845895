.create-course {
  position: relative;
  justify-content: center;
  width: 100%;

  .duration-info {
    align-items: center;
    display: flex;
    padding-top: 25px;
  }

  &__tab {
    padding: 0;

    &:first-child {
      &::after {
        content: "";
        width: 80px;
        height: 0;
        border: 1px dashed rgba(var(--color-grey-800-rgb), 0.2);
        margin-inline-end: 18px;
        margin-inline-start: 11.5px;
      }
    }

    &__icon {
      @extend .font-extrabold;
      border: 1px solid rgba(var(--color-grey-800-rgb), 0.2);
      width: 36px;
      height: 36px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: var(--color-grey-800);
    }

    &__text {
      @extend .font-bold;
    }
  }

  &__tab.nav-link {
    margin-left: 0 !important;
  }

  &__tab.nav-link.active {
    border-color: transparent !important;

    .create-course__tab__icon {
      background-color: var(--color-primary);
      border: var(--color-primary);
      color: #fff;

      &::after {
        content: "";
        width: 44px;
        height: 44px;
        background-color: rgba(var(--color-primary-rgb), 0.1);
        border-radius: 50%;
        position: absolute;
      }
    }
  }

  &__title {
    margin-bottom: 30px;
  }

  .create-content {
    background-color: var(--color-grey-100);
    padding: 28px 30px;
    border-radius: 20px;
    height: auto;
    justify-content: center;
  }

  .create-section {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #0000001a;
    border-radius: 20px;
    padding: 24px;
    align-items: start;

    &__title {
      margin-bottom: 30px;
    }

    &__label {
      margin-bottom: 13px;
    }

    &--level {
      &__menu-check {
        gap: 6rem;
        margin-top: 10px;
      }
    }

    &--info {
      &__upload-image {
        margin-bottom: 24px;

        .image-input {
          border-style: solid;
          margin-bottom: 0.5rem;
        }
      }
    }
    .dropdown-menu {
      width: 95%;
      max-height: 300px;
      overflow: auto;

      @media screen and (max-width: $small) {
        width: fit-content;
      }
    }

    .dropdown-toggle {
      display: flex;
      align-items: center;

      &::after {
        margin-right: auto;
      }
    }

    .remove-btn {
      position: absolute;
      left: 1.25rem;
      top: 30%;
      transform: translateY(0);

      &:hover {
        i {
          color: var(--color-red);
        }
      }
    }

    .create-lesson-section {
      background: #f5f5f6 0% 0% no-repeat padding-box;
      border-radius: 12px;
      opacity: 1;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      padding: 24px;

      &__title {
        margin-bottom: 28px;

        h3 {
          font-size: 17px;
        }
      }
    }
  }
}

.ReactTags__tags {
  position: relative;
  height: auto;

  .ReactTags__remove {
    color: #777777;
    font-size: 25px;
    line-height: 16px;
    vertical-align: sub;
    margin-inline-start: 14px;
  }
}

.ReactTags__tag {
  margin-right: 20px;
  border-radius: 15px;
}

.ReactTags__tagInput {
  border-radius: 2px;
  display: block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 50px;
  margin: 0;
  font-size: 16px;
  border: 1px solid rgba(#0e0b1d, 0.1);
  border-radius: 12px;
  padding: 21px;
  width: 100%;
  outline: rgba(14, 11, 29, 0.1);
}

.hide-input-tags {
  visibility: hidden;
}

.ReactTags__selected span.ReactTags__tag {
  display: inline-block;
  margin: 0 5px;
  background-color: rgba(#000, 0.05);
  background: #eee;
  color: var(--color-grey-800);
  font-size: 12px;
  padding-block: 6px 8px;
  padding-inline-start: 22px;
  padding-inline-end: 14px;
  border-radius: 10vmax;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  cursor: pointer;
}

.input-group {
  .did-floating-input {
    border-left: none !important;
    border-radius: 0px 12px 12px 0px !important;
  }
}

.currency-field {
  height: 48px;
  background: var(--color-grey-50);
  border-radius: 12px 0px 0px 12px !important;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.currency-menu {
  background: var(--color-grey-50);
  border-radius: 12px 0px 0px 12px;
  opacity: 1;
  height: 39px;

  .fa-chevron-down {
    left: 2px;
    right: 40px;
    margin-right: 20px;
  }

  .dropdown-menu {
    left: 0;
    right: auto;
    li {
      margin-left: 10px;
      font-size: larger;
    }
  }
}

.checkbox-main {
  display: flex;
  align-items: center;

  .form-check-input {
    margin-right: 12px;
    margin-left: 12px;
  }
}
