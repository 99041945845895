.user-profile-header {
  width: 100%;
  height: 140px;
  box-shadow: 0px 5px 21px #00000014;
  border-radius: 0;
  margin: 0;
  padding: 40px 29px 0 0;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
  }
}
