#ar {
  body {
    #maincontent {
      direction: rtl;
    }

    .ms-1 {
      margin-left: 0 !important;
      margin-right: 0.25rem !important;
    }

    .ms-2 {
      margin-left: 0 !important;
      margin-right: 0.5rem !important;
    }

    .ms-3 {
      margin-right: 1rem !important;
      margin-left: 0 !important;
    }

    .ms-4 {
      margin-left: 0 !important;
      margin-right: 1.5rem !important;
    }

    .ms-5 {
      margin-left: 0 !important;
      margin-right: 3rem !important;
    }

    .ms-auto {
      margin-left: 0 !important;
      margin-right: auto !important;
    }

    .me-auto {
      margin-left: auto !important;
      margin-right: 0 !important;
    }

    .ms-auto.me-auto{
      margin-right: auto !important;
      margin-left: auto !important;
    }

    .me-2 {
      margin-left: 0.5rem !important;
      margin-right: 0 !important;
    }

    .me-3 {
      margin-left: 1rem !important;
      margin-right: 0 !important;
    }

    .me-4 {
      margin-right: 0 !important;
      margin-left: 1.5rem !important;
    }

    .me-5 {
      margin-right: 0 !important;
      margin-left: 3rem !important;
    }

    .pe-3 {
      padding-right: 0 !important;
      padding-left: 1rem !important;
    }

    .pe-2 {
      padding-right: 0 !important;
      padding-left: 0.5rem !important;
    }

  }

  // icons rtl
  .fa-chevron-left:before {
    content: "\f054";
  }

  .fa-arrow-right:before {
    content: "\f060";
  }

  .fa-arrow-left:before {
    content: "\f061";
  }

  .fa-chevron-right:before {
    content: "\f053";
  }

  // login
  .login-page__banner {
    text-align: right;

    .content-employee ul li::before {
      margin-left: 12px;
      margin-right: -1em;
    }
  }

  // navbar
  .admin-layout {
    .navbar-main {
      @media only screen and (max-width:$Xlarge) {
        padding-left: 0;
        padding-right: 30px;
      }

       // logo
    .logos {
      li {
        &::before {
          left: 0;
          right: auto;
        }

      }
    }

      .setting-menu {
        .dropdown-toggle.arrow::after {
          margin-right: .255em;
          margin-left: 0;
        }

        .dropdown-menu {
          left: 0;
          right: auto;
        }
      }

      .main-menu .navbar-nav li:not(:first-of-type) {
        margin-left: 0;
        margin-right: 40px;
      }
    }

    .sidebar-main {
      @media only screen and (max-width:$Xlarge) {
        left: auto;
        right: -260px;

        .sidebar-main-toggle {
          left: auto;
          right: 10px;
        }

        &::before {
          left: auto;
          right: 0;
        }

        &.show {
          right: 0;
          left: auto;
          animation: right 2s;
        }
      }
    }
  }


  // inputs
  .input-text {
    direction: rtl;

    .did-floating-label {
      left: auto;
      right: 15px;
    }

    .eye-password {
      left: 20px;
      right: auto;
    }
  }

  .checkbox-main {
    text-align: right;
    padding-right: 0;
    padding-left: 0;

    .form-check-input {
      float: right;
      margin-left: 10px;
      margin-right: 0;
    }
  }

  .input-search {
    .input-icon {
      left: auto;
      right: 13px;
    }

    .form-control {
      padding-right: 40px;
      padding-left: 0;
    }
  }

  .select-with-icon {
    .input-text {
      left: auto;
      right: 13px;
    }

    .form-select {
      padding-left: 0;
      padding-right: 40px;

    }

    .input-icon {
      left: 13px;
      right: auto;
    }
  }

  .form-switch {
    text-align: right;
    padding-left: 2.5em;
    padding-right: 0;

    .form-check-input {
      float: left;
      margin-right: 0;
      margin-left: -2.5em;

    }
  }

  .radio-group {
    li:not(:last-of-type) {
      margin-right: 0;
      margin-left: 10px;
    }

    .radio-custom+.radio-custom-label:before {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  .content-page .table__content .item .tools .dropdown-menu {
    text-align: right;
  }

  // modals
  .main-modal {
    .modal-header {
      .btn-close {
        margin-right: auto;
        margin-left: 0;
      }

    }
  }


  // components
  .learinig-path-card {
    &__img {
      margin-left: 20px;
      margin-right: 0;
    }

    &__btn {
      margin-left: 0;
      margin-right: auto;

      @media only screen and (max-width:$medium) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 10px;
      }

    }
  }


  //
  .breadcrumb {
    .breadcrumb-item:not(:last-of-type)::after {
      float: left;
      padding-right: 10px;
      content: "\f053";
    }
  }

  .accordion {
    .accordion-button::after {
      margin-left: 0;
      margin-right: auto;

    }
  }

  .list-progressbar-cards {
    &-state {
      margin-right: 0;
      margin-left: 24px;

      @media only screen and (max-width:$small) {
        margin-left: 8px;
      }
    }

    li {
      &:not(:last-of-type) {
        .border-dashed {
          left: auto;
          right: 18px;
        }
      }
    }
  }

  .learinig-path-card.learinig-path-details-card .list-details li:not(:last-of-type) {
    margin-right: 0;
    margin-left: 10px;
  }


  // input-comment
  .input-comment {
    .icon {
      transform: rotate(0);
    }
  }

  .leader-board {
    &-card.bg-grey-radius {
      &::before {
        left: 0;
        right: -16px;
        background-color: rgba($color: #BBBABF, $alpha: 0.12);
        border-radius: 20px 0px 0px 20px;
      }
    }

    .myself-selected::after {
      right: -5px;
      left: auto;
      transform: rotate(0);
    }
  }

  .total-courses-card {
    .doughnut-chart {
      margin-left: 30px;
      margin-right: 0;
    }
  }

  .create-post-button {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .rtl-flip {
    transform: rotate(180deg);
  }

  .navbar-dropdown {
    .dropdown-menu {
      left: 0;
      right: auto;

      .notification__item--new::before {
        right: 8px;
        left: 0;
      }
    }
  }

  // notification
  .admin-layout .navbar-main .notification.navbar-dropdown .dropdown-menu {
    right: auto;
    left: -40px;

    &::before {
      left: 40px;
      right: auto;
    }

    @media only screen and (max-width: $Xlarge) {
      left: -65px;
      right: auto;
    }
  }

  .admin-layout .navbar-main .setting-menu .dropdown-menu {
    &::before {
      left: 40px;
      right: auto;
    }
  }

  .text-start {
    text-align: right !important;
  }
}