.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80px;

  p {
    color: var(--color-grey-200);
    font-weight: bold;
    font-size: 14px;
  }

  .select-with-radio {
    position: relative;
    height: 50px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 2px solid var(--color-grey-100);
    border-radius: 12px;
    flex: 1;

    &__value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;

      p {
        color: var(--color-grey-200);
        font-weight: normal;
        font-size: 14px;
      }
    }

    &__options {
      position: absolute;
      top: 50px;
      left: 0;
      width: 100%;
      height: 300px;
      overflow-y: auto;
      border: 1px solid var(--color-grey-100);
      border-radius: 0.5rem;
      padding: 0.5rem;
      background-color: var(--color-white);
      z-index: 1;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .radio-group {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 5px;
        margin-right: 30px;

        input[type="radio"] {
          margin-right: 5px;
        }
      }
    }

    .custom-radio {
      position: relative;
      padding-right: 30px;
      margin-bottom: 12px;
      cursor: pointer;
      user-select: none;
    }

    .custom-radio input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    /* Custom radio indicator */
    .custom-radio__checkmark {
      position: absolute;
      top: 0;
      right: 0;
      height: 20px;
      width: 20px;
      margin-top: 10px;
      border: 4px solid #eee;
      background-color: #eee;
      border-radius: 50%;
    }

    .custom-radio input:checked ~ .custom-radio__checkmark {
      background-color: var(--color-primary);
    }
  }
}
