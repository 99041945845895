.modal-with-actions-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;

    &__image {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #f7e7e8;
    }

    &__title {
      font-size: 22px;
      font-weight: 800;
    }

    &__text {
      font-size: 16px;
      color: var(--color-grey-200);
      max-width: 358px;
      text-align: center;
    }

    &__buttons {
      width: 100%;
      align-self: flex-end;
      flex-direction: row-reverse;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      &__cancel {
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
      }

      &__delete {
        background-color: var(--color-primary);
      }
    }
  }