.social-links {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 4px;
  padding: 8px;

  &__title {
    width: 49px;
    height: 49px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: rgba(136, 136, 136, 1);
    margin-bottom: 8px;
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(136, 136, 136, 0.1);
  }
}
