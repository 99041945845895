.select-with-text-wrapper {
  position: relative;
  height: 50px;
  cursor: pointer;
  width: 250px;
  box-shadow: 0px 0px 5px #00000029;
  border-radius: 12px;
  border: 2px solid var(--color-grey-100);

  .select-with-text {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 12px;

    &__right {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0;

      &-title {
        font-size: 12px;
        margin: 0;
        color: var(--color-grey-200);
      }

      &-value {
        margin: 0;
        font-size: 14px;
        margin: 0;
        color: var(--color-grey-800);
      }
    }
  }

  .drop-down {
    position: absolute;
    width: 250px;
    z-index: 1;
    background-color: #fff;
    margin-top: 8px;
    row-gap: 20px;
    padding: 12px 8px;
    border-radius: 12px;
    box-shadow: 0px 0px 5px #00000029;
    padding: 12px 8px;

    &__item {
      display: flex;
      align-items: center;
      width: 250px;
      cursor: pointer;
      column-gap: 4px;
      padding: 8px;

      &__dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      &__label {
        margin: 0;
        font-size: 14px;
        color: var(--color-grey-800);
        font-weight: bold;
      }
    }
  }
}
