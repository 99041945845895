.search-container {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 12px;
  .search-form {
    display: flex;
    align-items: center;
    column-gap: 8px;
    border: 1px solid var(--color-grey-200);
    border-radius: 8px;
    width: auto;
    max-width: 300px;
    padding: 12px;

    .search-input {
      border: 0;
      outline: 0;
      width: 100%;
      background-color: transparent;
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 500;

      &:focus,
      &:active {
        outline: none;
      }
    }
  }

  .sort-container {
    cursor: pointer;
    position: relative;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--color-grey-200);
    border-radius: 8px;
    padding: 12px;

    &__right-side {
      display: flex;
      align-items: center;
      column-gap: 12px;
      span {
        color: var(--color-grey-200);
        font-size: 16px;
      }
    }
    .filter-popup {
      position: absolute;
      top: 50px;
      right: 0;
      z-index: 10;
      width: 200px;
      background-color: #fff;
      border: 1px solid var(--color-grey-200);
      border-radius: 8px;
      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;

        li {
          cursor: pointer;
          border-bottom: 1px solid var(--color-grey-100);
          padding: 12px;

          &:hover {
            background-color: var(--color-grey-100);
          }
        }
      }
    }
  }
}
