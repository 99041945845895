.user-training-courses {
  width: 100%;
  max-width: 1026px;
  height: 100%;
  padding: 14px 29px;

  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &__card {
      width: 100%;
      // height: 300px;
      display: flex;
      column-gap: 12px;

      &__image {
        width: 160px;
        height: 100px;
        background-color: #f8f8f8;
        border-radius: 10px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        &__progress {
          width: 300px;
          height: 8px;
          background-color: #f8f8f8;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }
}
