.dashboard {
  &__create-course {
    padding: 0.83rem 1.875rem;

    &__icon {
      padding-inline-end: 0.75rem;
    }
  }

  &__summary-reports {
    margin-top: 1.875rem;

    &__content {
      margin-top: 1.25rem;

      .home-stats-card {
        position: relative;
        padding: 26px 21px 24px;
        box-shadow: 0px 0px 5px #00000014;

        .stats-show-details {
          position: absolute;
          left: 1rem;
          top: 1rem;

          &__icon {
            padding-inline-start: 0.5rem;
          }
        }
      }

      .upload-profile{
        margin-inline-end: 14px;
      }
    }
  }

}