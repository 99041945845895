.login-page {
  padding: 40px 20px;

  .container-fluid>.row {
    min-height: 90vh;
  }

  &__content {
    max-width: 400px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    .content-header {
      text-align: center;
      margin-bottom: 40px;

      .logo {
        width: 180px;
        height: 110px;
        margin: auto;
      }

      h1 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: bold;
      }

      p {
        font-size: 16px;
        color: rgba($color: #333, $alpha: 0.7);
      }
    }

    .content-form {
      text-align: center;

      .btn-main-color {
        margin-bottom: 28px;
      }

      .reset-password {
        text-align: center;
      }
    }
  }

  &__banner {
    background: transparent 0% 0% no-repeat padding-box;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    text-align: left;
    align-items: flex-end;

    @media only screen and (max-width: $large) {
      display: none;
    }

    .content-admin {
      color: var(--color-white);
      padding: 50px;

      h1 {
        font-size: 40px;

        // text-align: right;
        span {
          font-size: 50px;
          display: #000;
          font-weight: bold;
          display: block;
        }
      }

      p {
        font-size: 24px;
        margin-top: 26px;
      }
    }

    .content-employee {
      color: #000;
      padding: 50px;
      height: 100%;
      width: 100%;

      .banner-flag-container {
        position: relative;
        width: 216px;
        height: 236px;
        margin: -90px auto 50px;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;

        .banner-flag {
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 1;
        }

        .logo {
          width: 150px;
          height: 150px;
          position: absolute;
          z-index: 2;
          left: 0;
          right: 0;
          margin: auto;

        }
      }

      h1 {
        font-size: 31px;

        // text-align: right;
        span {
          font-size: 39px;
          display: #000;
          font-weight: bold;
          display: block;
        }
      }

      p {
        font-size: 24px;
        margin-top: 47px;
        font-weight: bold;
      }

      ul {
        li {
          font-size: 24px;
          color: var(--color-grey-400);
          font-weight: normal;
          padding-right: 30px;

          // position: relative;
          &::before {
            // position: absolute;
            content: "\2022";
            color: var(--color-primary);
            font-weight: bold;
            display: inline-block;
            margin-right: 12px;
            margin-left: -1em;
            font-size: 30px;
          }
        }
      }
    }
  }
}