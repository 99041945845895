.text-field {
  display: flex;
  width: 100%;
  flex-direction: column;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 14px;
    color: var(--color-grey-200);
    font-weight: bold;
    letter-spacing: 0;
  }

  .text-field__input {
    width: 100%;
    height: 100%;
    border: 2px solid var(--color-grey-100);
    border-radius: 12px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 16px;

    &:focus {
      outline: 0;
    }
  }

  .text-field__input::placeholder {
    color: var(--color-grey-200);
    font-size: 14px;
    font-weight: normal;
  }

  .error {
    color: red;
    font-size: 12px;
    margin-top: 8px;
  }
}
