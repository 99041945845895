.circle-bg {
  fill: none;
  stroke: #ddd;
}

.circle-progress {
  fill: none;
  stroke: #51caad;
}

.percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
