.app-tabs {
  .nav-tabs {
    border: none;

    .nav-link {
      @extend .font-bold;
      border: none;
      color: var(--color-grey-400);
      padding-bottom: 10px;
      margin-inline-end: 2.5rem;
      padding-inline: 0;

      &.active {
        @extend .font-extrabold;
        color: var(--color-grey-800);
        position: relative;

        &::after {
          content: "";
          width: 1.25rem;
          height: 6px;
          background-color: var(--color-primary);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          border-radius: 3px;
        }
      }
    }
  }
}